<template>
    <div class="container">
        <header class="jumbotron">
            <h3>Lista użytkowników</h3>
        </header>
        <DataTable :value="content" v-model:filters="filters" :loading="loading" removableSort responsiveLayout="scroll"
                   :globalFilterFields="['username', 'firstName', 'lastName', 'email']">
            <template #header>
                <div class="p-d-flex p-jc-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search"/>
                            <InputText v-model="filters['global'].value" placeholder="Wyszukaj"/>
                        </span>
                </div>
            </template>
            <template #empty>
                Nie znalieziono użytkowników
            </template>
            <template #loading>
                Ładwanie użytkowników. Proszę czekać
            </template>
            <Column field="username" header="Login" :sortable="true"></Column>
            <Column field="firstName" header="Imie" :sortable="true"></Column>
            <Column field="lastName" header="Nazwisko" :sortable="true"></Column>
            <Column field="email" header="e-mail" :sortable="true"></Column>
            <Column headerStyle="width: 12em" bodyStyle="text-align: center; display: inline-block;">
                <template #body="slotProps">
                    <Button type="button" label="Edytuj"
                            v-on:click="editUser(slotProps.data.id)"></Button>
                    <Button type="button" class="p-button-danger" label="Usuń"
                            v-on:click="removeUser(slotProps.data.id)"></Button>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import UserService from "../../services/user.service";
    import {FilterMatchMode} from 'primevue/api';

    export default {
        inject: ['global'],
        name: "UsersList",
        data() {
            return {
                content: null,
                filters: {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH}
                },
                loading: true,
            };
        },
        methods: {
            editUser: function (id) {
                this.$router.push({path: `${this.global.state.instancePatch}/uzytkownicy/edytuj/${id}`});
            },
            removeUser: function (id) {
                UserService.removeUser(id).then(
                    () => {
                        this.getList();
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Usunięto',
                            detail: 'Poprawnie usunięto użytkownika',
                            life: 3000
                        });
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },
            getList: function () {
                UserService.getAllUsers().then(
                    (response) => {
                        this.loading = false;
                        this.content = response.data.items;
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },
        },
        mounted() {
            this.getList();
        },
    };
</script>
